import FirebaseModel from './firebaseModel';

const setDefaultAttributes = function() {
  this.setAttribute('company', {});
  this.setAttribute('location', {});
  this.setAttribute('duties', []);
}

function History(snapshot, collection = 'candidate_historys') {
  if (snapshot) {
    FirebaseModel.call(this, snapshot, snapshot.ref, collection);
  } else {
    FirebaseModel.call(this, null, null, collection);

    setDefaultAttributes.apply(this);
  }
}

History.prototype = Object.create(FirebaseModel.prototype);
History.prototype.constructor = FirebaseModel;

export default History;
