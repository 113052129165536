import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as Logo } from '../images/heart-briefcase.svg';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  logo: {
    height: 200,
    width: 200,
  },
}));

export default function LoadingScreen(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="animated infinite tada">
        <Logo className={classes.logo} />
      </div>
    </div>
  );
}
