import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
} from '@material-ui/core';
import {
  Autocomplete,
 } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  autocompleteRoot: {
    width: '100%',
  },
  autocompleteInput: {
    width: '100%',
  },
  textField: {
    '& .MuiInput-input': {
      width: '100%',
    }
  },
}));

export default function StyledAutocomplete(props) {
  const classes = useStyles();

  const {
    defaultValue = '',
    disableClearable = false,
    disabled = false,
    disableOpenOnFocus = false,
    getOptionLabel = option => option,
    freeSolo = true,
    helperText = 'is required',
    label,
    onChange = _ => { throw new Error('on change is handler is required') },
    options = [],
    placeholder = '',
    renderInput = (params, value) => (
      <TextField {...params}
        className={classes.textField}
        error={(!disabled && required && !defaultValue) ? true : undefined}
        fullWidth={props.fullWidth || true}
        helperText={(!disabled && required && !defaultValue) && helperText}
        label={label}
        onBlur={handleBlur}
        placeholder={placeholder}
        required={required} />
    ),
    renderOption,
    required = true,
  } = props;

  const handleBlur = (event) => {
    onChange(event, event.target.value);
  }

  const handleChange = (event, value) => {
    onChange(event, value);
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        classes={{
          root: classes.autocompleteRoot,
          inputRoot: classes.autocompleteInput,
        }}
        defaultValue={defaultValue}
        disableClearable={disableClearable}
        disabled={disabled}
        disableOpenOnFocus={disableOpenOnFocus}
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        options={options}
        renderInput={renderInput}
        renderOption={renderOption} />
    </div>
  );
};
