import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Badge, Chip } from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';

import { concat } from '../../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
  },
  avatar: {
    height: 24,
    width: 24,
  },
  icon: {
    color: '#e2325a !important',
  },
  invisible: {
    display: 'none',
  },
}));

export default function InterviewReminder(props) {
  const classes = useStyles();

  const {
    contacts = [],
    feedback,
    label,
    onClick,
    starts_at,
  } = props;

  const avatar = contact => {
    return <Avatar className={classes.avatar} src={contact.image_url} />;
  }

  return (
    <div>
      <Chip
        avatar={
          <Badge badgeContent={contacts.length} color="secondary">
            {contacts.length === 1 ? avatar(contacts[0]) : undefined}
            {contacts.length > 1 ? avatar({}) : undefined}
          </Badge>
        }
        className={!feedback ? classes.chip : classes.invisible}
        label={concat(label, ': in ', formatDistanceToNow(new Date(starts_at)))}
        onClick={onClick}
        variant="outlined" />
    </div>
  );
}

