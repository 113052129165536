import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles(theme => ({
  root: {
  },
  label: {
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'relative',
    width: 80,
  },
  image: {
    border: '1px solid #333333',
    height: 80, 
    width: 80,
  },
  inputFile: {
    display: 'none',
  },
  invisible: {
    display: 'none',
  },
  uploadIconButton: {
    color: '#dddddd',
    height: 40,
    width: 40,
  },
  uploadIconOverlay: {
    alignSelf: 'center',
    color: 'darkgray',
    position: 'absolute',
    zIndex: 8,
  },
}));

export default function UploadableImage(props) {
  const classes = useStyles();

  const {
    onCapture = _ => {
      throw new Error('must implement on Capture')
    },
    src,
  } = props;

  const [uploadVisible, setUploadVisible] = React.useState(false);

  return (
    <div>
      <label
        className={classes.label}
        htmlFor="image-button-file"
        onMouseOver={_ => setUploadVisible(true)}
        onMouseOut={_ => setUploadVisible(false)}
      >
        <div className={classes.uploadIconOverlay}>
          <IconButton
            aria-label="upload picture"
            className={!uploadVisible ? classes.invisible : classes.uploadIconButton}
            component="span"
          >
            <PhotoCamera
              className={!uploadVisible ? classes.invisible : classes.uploadIconButton}
              size="large" />
          </IconButton>
        </div>

        <img alt="upload icon" className={classes.image} src={src} />
      </label>
      <input
        accept="image/*"
        className={classes.inputFile}
        id="image-button-file"
        onChange={onCapture}
        type="file"
      />
    </div>
  );
}
