import {
  differenceInDays,
  differenceInMilliseconds,
  differenceInMinutes,
} from 'date-fns';
import { max, min, values } from './aide';

const MILLI_SECONDS = 1000;
const MILLI_MINUTES = MILLI_SECONDS * 60;
const MILLI_HOURS = MILLI_MINUTES * 60;

const ACTION_BASIS = {
  research: 8 * MILLI_HOURS,
  outreach: 80 * MILLI_HOURS,
  interviewing: 248 * MILLI_HOURS,
}

const REQUIRED_FIELDS = {
  research: [
    'business_model', 'competitors', 'customer',
    'employee_count', 'funding_stage', 'industry',
  ],
  outreach: [
    'cover_letter', 'cover_letter_response',
  ],
  interviewing: [
  ],
}

const SECTION_FIELDS = {
  research: [
    'business_model', 'competitors', 'customer',
    'employee_count', 'funding_stage', 'industry',
  ],
  outreach: [
    'cover_letter', 'cover_letter_response',
  ],
  interviewing: [
  ],
};

export default function({ company, state, meta: { updates = {} } = { updates: {} }, closed_at, created_at }) {
  return function(researchFields, outreachFields, interviewFields) {
    return (function(analytics) {
      return {
        ...analytics,
        overallTimeToAct: analytics[state] && analytics[state].timeToAct,
      };
    })({
      started: new Date(created_at),
      ended: !!closed_at ? new Date(closed_at) : null,

      research: sectionMetrics(
        'research',
        dateOr( min(values({
          ...researchFields,
          created_at: created_at,
        })), [Infinity], null ),
        ended( REQUIRED_FIELDS.research, researchFields ),
      ),

      outreach: sectionMetrics(
        'outreach',
        dateOr( min(values({
          ...outreachFields,
          created_at: created_at,
        })), [Infinity], null ),
        ended( REQUIRED_FIELDS.outreach, outreachFields ),
      ),

      interviewing: sectionMetrics(
        'interviewing',
        dateOr( min(values({
          ...interviewFields,
          created_at: created_at,
        })), [Infinity], null ),
        ended( REQUIRED_FIELDS.interviewing, interviewFields ),
      ),
    });
  }(
    intersectingFields(SECTION_FIELDS.research, updates),
    intersectingFields(SECTION_FIELDS.outreach, updates),
    intersectingFields(SECTION_FIELDS.interviewing, updates),
  );
}

const dateOr = function(value, comparers = [], alternative = null) {
  return comparers.includes(value) ?
    alternative :
    new Date(value);
}

const ended = function(fields, updates) {
  if (fields.every(f => !!updates[f])) {
    return dateOr( max(values(updates)), [-Infinity], null );
  }

  return null;
}

const intersectingFields = function(list, object) {
  return list.reduce(function(acc, field) {
    if (!!object[field]) {
      acc[field] = object[field];
    }

    return acc;
  }, {});
}

const sectionMetrics = function(section, started, ended) {
  return (function([millis, minutes, days]) {
    return {
      started_at: started,
      ended_at: ended,
      completed: !!started && !!ended,
      durationInMillis: millis,
      durationInMinutes: minutes,
      durationInDays: days,
      timeToAct: ACTION_BASIS[section] - millis,
    };
  })(sectionMetricsDuration(started, ended));
}

const sectionMetricsDuration = function(started, ended) {
  if (!!started && !ended) {
    return [
      differenceInMilliseconds(new Date(), started),
      differenceInMinutes(new Date(), started),
      differenceInDays(new Date(), started),
    ];
  } else if (!!started && !!ended) {
    return [
      differenceInMilliseconds(ended, started),
      differenceInMinutes(ended, started),
      differenceInDays(ended, started),
    ];
  } else {
    return [0, 0, 0];
  }
}
