import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Job } from '../models';
import WithNavigation from './WithNavigation';
import toAnalyticsData from '../common/analyticsData';
import JobsArchivedTable from '../components/JobsArchivedTable';
import { useFirestore } from '../hooks/firebase';

function jobsReducer(state, changes) {
  const jobs = state.reduce((acc, job) => {
    acc[job.id] = job;

    return acc;
  }, {});

  changes.forEach(change => {
    switch (change.type) {
      case 'removed':
        delete jobs[change.doc.id];
        break;

      default:
        jobs[change.doc.id] = new Job(change.doc);
    }
  });

  return Object.keys(jobs).map(key => jobs[key]);
}

const useStyles = makeStyles(theme => ({
  root: {
  },
  features: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  h2: {
    fontSize: 22,
    fontWeight: 200,
    lineHeight: 1.26,
  },
}));

export default function Archived(props) {
  const classes = useStyles();
  const firestore = useFirestore();

  const [jobs, dispatch] = React.useReducer(jobsReducer, []);

  React.useEffect(
    _ => {
      const unsubscribe = firestore
        .queryJobArchivedList(snapshot => dispatch(snapshot.docChanges()));
    
      return _ => unsubscribe();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <WithNavigation
      {...props}
      title={
        <Typography className={classes.h2} variant="h2">
          Archived
        </Typography>
      }
    >
      <div className={classes.root}>
        <JobsArchivedTable {...props} data={
          limitToArchived(jobs)
            .map(toFeatureData)
          } />
      </div>
    </WithNavigation>
  );
}

const limitToArchived = function(jobs) {
  return jobs
    .filter(job => Job.ARCHIVED_STATES.includes(job.attributes.state));
}

const toFeatureData = function(job) {
  return {
    id: job.id,
    attributes: job.attributes,
    job: job,
    analytics: toAnalyticsData(job.attributes),
  };
}
