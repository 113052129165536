import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Box, Dialog, DialogActions, DialogContent, DialogTitle,
 } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { AutoResizeInput } from '../fields';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const useStyles = makeStyles(theme => ({
  root: {
  },
  actions: {
  },
  buttons: {
  },
  dialogContent: {
    minWidth: 350,
  },
  fieldset: {
    flexGrow: 1,
    marginBottom: 0,
  },
  icon: {
    height: 32,
    width: 32,
  },
  rating: {
    '& .MuiRating-iconFilled': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
  },
}));

export default function InterviewFeedback(props) {
  const classes = useStyles();

  const {
    index,
    job,
    onClose,
    open,
  } = props;

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon className={classes.icon} />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon className={classes.icon} />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon className={classes.icon} />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon className={classes.icon} />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon className={classes.icon} />,
      label: 'Very Satisfied',
    },
  };

  const IconContainer = function(props) {
    const { value, ...other } = props;

    return <span {...other}>{customIcons[value].icon}</span>;
  }

  const [scroll, ] = useState('paper');
  const [rating, setRating] = useState(-1);
  const [notes, setNotes] = useState('');

  const missingRankOrNote = event => {
    return rating === undefined || !notes;
  }

  const onRatingChange = key => (event, value) => setRating(value);

  const onSave = event => {
    const interviews = job.attributes.interviews.map(interview => {
      return {...interview};
    })

    interviews[index].feedback = {
      notes: notes,
      rating: rating,
    };

    job.setAttribute('interviews', interviews);
    return job.save()
      .then(_ => console.log('saved'));
  }

  const noteElementRef = useRef(null);

  useEffect(_ => {
    if (open) {
      const { current: noteElement } = noteElementRef;

      if (noteElement !== null) {
        noteElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        scroll={scroll}
      >
        <DialogTitle id="scroll-dialog-title">
          Interview Notes
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers={scroll === 'paper'}>
          <AutoResizeInput
            onChange={event => setNotes(event.target.value)}
            placeholder="Add your notes"
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Box borderColor="transparent" className={classes.fieldset} component="fieldset" mb={3}>
            <Rating
              name="customized-icons"
              defaultValue={3}
              getLabelText={value => customIcons[value].label}
              IconContainerComponent={IconContainer}
              onChange={event => onRatingChange('rating')(event, event.target.value)}
              size="large"
              value={Number(rating)}
            />
          </Box>

          <div className={classes.buttons}>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button disabled={missingRankOrNote()} onClick={onSave} color="primary">
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
