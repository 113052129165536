import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../hooks/firebase';
import { Loading } from '.';

export default function PrivateRoute(props) {
  const { component: RouteComponent, ...rest } = props;
  const user = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        if (user.loading) {
          return <Loading />;
        } else if (!user.loading && !!user.model) {
          return <RouteComponent {...props} />;
        } else {
          return <Redirect to={'/signin'} />;
        }
      }}
    />
  );
}
