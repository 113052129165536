import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  ExpansionPanel, ExpansionPanelActions,
  ExpansionPanelDetails, ExpansionPanelSummary,
  TextField, Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTimeOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';
import GroupIcon from '@material-ui/icons/GroupOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import NotesIcon from '@material-ui/icons/NotesOutlined';
import StarIcon from '@material-ui/icons/StarBorderOutlined';
import TitleIcon from '@material-ui/icons/TitleOutlined';
import Markdown from 'react-markdown';

import {
  AutoResizeInput, Contacts, DateTimePicker, InterviewTypes
} from '.';
import { concat, dashcase } from '../../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
    width: '100%',
  },
  action: {
    display: 'flex',
    justifySelf: 'flex-end',
  },
  details: {
    flexDirection: 'column',
    paddingBottom: 0,
  },
  disabledColor: {
    color: '#9e9e9e',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  expansionPanelActions: {
    padding: '0 24px 16px 24px',
  },
  field: {
    alignSelf: 'center',
    fontSize: theme.typography.pxToRem(16),
  },
  helper: {
    alignSelf: 'center',
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 2),
  },
  icon: {
    marginRight: 20,
    marginTop: 5,
  },
  inputPaddingTop: {
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    width: '100%',
  },
  search: {
    width: 300,
  },
  summary: {
    paddingRight: '12px !important',
  },
  title: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default function InterviewPanel(props) {
  const classes = useStyles();

  const {
    onChange,
    onRemove,
  } = props;

  const [interview, setInterview] = React.useState(props.interview);
  const [expanded, setExpanded] = React.useState(!interview.feedback);

  const handleBlur = (field, value) => {
    setInterview({
      ...interview,
      [field]: value,
    });

    onChange({
      ...interview,
      [field]: value,
    });
  }

  const handleChange = (field, value) => {
    handleBlur(field, value);
  }

  const handleRemove = event => {
    onRemove(event);
  }

  return (
    <div className={classes.root} id={concat(dashcase(interview.label), '-', interview.index)}>
      <ExpansionPanel defaultExpanded={expanded} onChange={event => setExpanded(!expanded)}>
        <ExpansionPanelSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.title}>
            {interview.label}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <div className={classes.row}>
            <TitleIcon className={classes.icon} />
            <InterviewTypes
              defaultValue={interview.label}
              disabled={!!interview.feedback}
              fullWidth={true}
              onChange={(event, value) => handleChange('label', value ? value : '')} />
          </div>

          <div className={classes.row}>
            <AccessTimeIcon className={classes.icon} />
            <DateTimePicker
              defaultValue={interview.starts_at}
              disabled={!!interview.feedback}
              disablePast={false}
              error={!interview.starts_at ? true : undefined}
              fullWidth={false}
              onChange={date => handleChange('starts_at', date)}
              required={true} />
          </div>

          <div className={classes.row}>
            <GroupIcon className={classes.icon} />
            <div className={classes.search}>
              <Contacts
                contacts={interview.contacts}
                disabled={!!interview.feedback}
                onChange={(event, array) => handleChange('contacts', array)}
                placeholder="Add interviewers" />
            </div>
          </div>

          <div className={classes.row}>
            <LocationOnIcon className={classes.icon} />
            <TextField
              defaultValue={interview.location}
              disabled={!!interview.feedback}
              fullWidth={true}
              onBlur={event => handleBlur('location', event.target.value)} />
          </div>

          <div className={classes.row}>
            <NotesIcon className={classes.icon} />
            <AutoResizeInput
              defaultValue={interview.prep_notes}
              disabled={!!interview.feedback}
              error={!interview.prep_notes ? true : undefined}
              fullWidth={true}
              onChange={event => handleBlur('prep_notes', event.target.value)} />
          </div>

          {!!interview.feedback ? (
            <div>
              <Divider className={classes.divider} />

              <div className={classes.row}>
                <StarIcon className={classes.icon} />
                <div className={classes.field}>
                  <Typography className={classes.disabledColor} variant="body1">
                    {interview.feedback.rating}
                  </Typography>
                </div>
              </div>

              <div className={classes.row}>
                <NotesIcon className={classes.icon} />
                <div className={classes.helper}>
                  <Markdown className={classes.disabledColor} source={interview.feedback.notes} />
                </div>
              </div>
            </div>
          ) : undefined}
        </ExpansionPanelDetails>
        <ExpansionPanelActions className={classes.expansionPanelActions}>
          {!interview.feedback ? (
            <Button color="default" onClick={handleRemove} size="small" variant="contained">
              Remove
            </Button>
          ) : <div />}
        </ExpansionPanelActions>
      </ExpansionPanel>
    </div>
  );
}
