import FirebaseModel from './firebaseModel';

const setDefaultAttributes = function() {
  this.setAttribute('company', {});
  this.setAttribute('location', {});
  this.setAttribute('duties', []);
}

function Job(snapshot, collection = 'candidate_jobs') {
  if (snapshot) {
    FirebaseModel.call(this, snapshot, snapshot.ref, collection);
  } else {
    FirebaseModel.call(this, null, null, collection);

    setDefaultAttributes.apply(this);
  }

  if (!this.state) {
    this.setAttribute('closed_at', null);
  }

  this.withdraw = reason => {
    this.setAttribute('state', 'closed');
    this.setAttribute('closed_reason', reason);
    this.setAttribute('closed_at', new Date());

    return this.save();
  }

  const save = this.save;

  this.save = _ => {
    this.setAttribute('state', getStateFromAttributes(this.attributes));
    return save();
  }
}

Job.prototype = Object.create(FirebaseModel.prototype);
Job.prototype.constructor = FirebaseModel;

const REQUIRED_FIELDS = {
  request: [
    'company', 'location',
    // 'source',
    'title',
  ],
  research: [
    'funding_stage', 'industry', 'employee_count',
    'competitors', 'business_model', 'customer',
  ],
  outreach: [
    'cover_letter', 'cover_letter_response',
  ],
  interviewing: [
  ]
}

const STEPS = [
  {id: 0, label: 'Request'},
  {id: 1, label: 'Research'},
  {id: 2, label: 'Outreach'},
  {id: 3, label: 'Interviewing'},
];

const STATES = STEPS
  .reduce((acc, step) => {
    acc[step.label.toUpperCase()] = step.label.toLocaleLowerCase();
    return acc;
  }, {});

const getStateFromAttributes = change => {
  let state = STATES.REQUEST;

  if (!!change.closed_reason) {
    return 'closed';
  }

  if (state === STATES.REQUEST && hasRequiredFields(change, STATES.REQUEST)) {
    state = STATES.RESEARCH;
  }

  if (state === STATES.RESEARCH && hasRequiredFields(change, STATES.RESEARCH)) {
    state = STATES.OUTREACH;
  }

  if (state === STATES.OUTREACH && hasRequiredFields(change, STATES.OUTREACH)) {
    state = STATES.INTERVIEWING;
  }

  return state;
}

const hasRequiredFields = (change, key) => {
  const fields = REQUIRED_FIELDS[key];

  if (fields) {
    return fields.every(field => !!change[field]);
  } else {
    return false;
  }
}

//const DATE_FIELDS = {
//  RESEARCH: [
//    'funding_stage_at', 'industry_at', 'employee_count_at',
//    'competitors_at', 'business_model_at', 'customer_at',
//  ],
//}

Job.ARCHIVED_STATES = [
  'archived',
  'closed',
];

Job.INPROCESS_STATES = [
  'research',
  'outreach',
  'interviewing',
];

export default Job;
