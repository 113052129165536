import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu, MenuItem, ListItemText,
} from '@material-ui/core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    elevation={4}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenu(props) {
  const {
    options = [],
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    setAnchorEl(null);
  };

  return (
    <div>
      {React.createElement(props.children.type, {
        ...props.children.props,
        onClick: handleClick,
      })}
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        disableAutoFocusItem={true}
        keepMounted
        onClick={handleClose}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem key={`customized-menu-${index}`}>
            <ListItemText onClick={event => option.onClick(option, event)} primary={option.label} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}