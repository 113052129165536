import React from 'react';

import StyledAutocomplete from './StyledAutocomplete';

const OPTIONS = [
  '1-10',
  '11-50',
  '51-100',
  '101-250',
  '251-500',
  '500+',
]

export default function FundingStages(props) {
  const {
    defaultValue = '',
    disabled = false,
    fullWidth,
    onChange,
    required,
  } = props;

  return <StyledAutocomplete
  defaultValue={defaultValue}
  disabled={disabled}
  freeSolo={false}
  fullWidth={fullWidth}
  label="Employee Count"
  onChange={onChange}
  options={OPTIONS}
  required={required} />;
};
