import { parseISO } from 'date-fns';
import React from 'react';
import {
  DateTimePicker,
} from '@material-ui/pickers';

const createDefaultDate = value => {
  const type = typeof value;

  if (value instanceof Date) {
    return value;
  } else if (type === 'number') {
    return new Date(value);
  } else if (type === 'string' && !value) {
    return null;
  } else if (type === 'string') {
    return parseISO(value);
  } else {
    return null;
  }
}

export default function _DateTimePicker(props) {
  const {
    ampm = true,
    className,
    disabled = false,
    disablePast = true,
    error,
    format = 'yyyy/MM/dd hh:mm a',
    fullWidth = true,
    hideTabs = true,
    InputProps,
    label = null,
    onChange = _ => {},
    required = false,
    showTodayButton = true,
  } = props;

  const value = createDefaultDate(props.defaultValue);

  return <DateTimePicker
    ampm={ampm}
    className={className}
    disabled={disabled}
    disablePast={disablePast}
    error={!disabled && error}
    emptyLabel=""
    format={format}
    fullWidth={fullWidth}
    InputProps={InputProps}
    label={label}
    onChange={onChange}
    onError={(error, value) => {
      error && console.log(error)
    }}
    helperText={(!!required && !disabled && !value) ? 'is required' : undefined}
    hideTabs={hideTabs}
    required={required}
    showTodayButton={showTodayButton}
    value={value}
  />;
}
