import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Fade, IconButton, Paper, Typography,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/LinkOutlined';
import ViewColumnIcon from '@material-ui/icons/ViewColumnOutlined';

import WithNavigation from './WithNavigation';
import { ButtonWithMenu, StateProgress } from '../components/fields';
import {
  Interviewing, Outreach, Research,
} from './sections';
import { Loading } from '.';
import { useFirestore, useNavigations } from '../hooks/firebase'
import { Job } from '../models';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  actions: {
    display: 'flex',
    '& > *': {
      marginTop: 'auto',
      marginRight: 5,
      marginBottom: 'auto',
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  document: {
    backgroundColor: '#ffffff',
    minWidth: 515,
    position: 'relative',
  },
  h2: {
    fontSize: 22,
    fontWeight: 200,
    lineHeight: 1.26,
  },
  iframe: {
    border: 0,
    height: '100%',
    width: '100%',
  },
  invisible: {
    display: 'none',
  },
  marginRight: {
    marginRight: 8,
  },
  preview: {
    backgroundColor: '#ffffff',
    minWidth: '50%',
    marginLeft: 20,
  },
  progressWrapper: {
    backgroundColor: '#ffffff',
    height: 15,
    paddingBottom: 20,
    position: 'absolute',
    width: '100%',
    zIndex: 10,
  },
  sections: {
    paddingTop: 20,
  },
  title: {
    fontWeight: 500,
    marginRight: 5,
  },
  titleHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));

function jobReducer(state, job) {
  return {
    state: 'complete',
    job: job,
  };
}

export default function JobShow(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const navigate = useNavigations(props.history);

  const {
    id,
  } = props.match.params;

  const [{
    job,
  }, dispatch] = React.useReducer(jobReducer, {status: 'loading', job: null});

  const [previewing, setPreviewing] = React.useState(false);

  React.useEffect(
    _ => {
      const unsubscribe = firestore
        .queryJobShow(id, snapshot => {
          if (snapshot.exists) {
            dispatch(new Job(snapshot));
          }
        });
    
      return _ => unsubscribe();
    },
    // eslint-disable-next-line
    []
  );

  const steps = getSteps();

  const closeJob = (event, option) => {
    job
      .withdraw(option.value)
      .then(_ => navigate.toTodoList());
  }

  const getActiveStepId = state => {
    return (steps.find(step => {
      return state === step.label.toLowerCase();
    }) || {}).id;
  }

  const onChangeset = key => value => {
    job.setAttribute(key, value);
    dispatch(job);
  }

  const handleSave = event => {
    event.preventDefault();
    event.currentTarget.disabled = true;

    job.save();
  }

  const onScroll = (event, id) => {
    const element = document.getElementById(id);
    const content = document.querySelector('#content');
    
    content.scrollBy(0, element.offsetTop);
  }

  if (job) {
    const {
      company = {},
      location = {},
      state,
      title,
      url,
      disabled = (state === 'closed'),
    } = job.attributes;

    return (
      <Fade in={true} onEnter={(node, done) => {
        if (props.history.location.hash) {
          const element = document.querySelector(props.history.location.hash);

          if (element) {
            document.querySelector('#content').scrollBy(0, element.offsetTop);
          }
        }
      }}>
        <WithNavigation
          {...props}
          title={
            <div className={classes.titleHeader}>
              <Typography className={classes.h2} variant="h2" noWrap>
                <span className={classes.marginRight}>{company && company.name}</span>
                <span className={classes.title}>{title}</span>
                <span className={classes.marginRight}>{location && location.city}</span>
              </Typography>
            </div>
          }
          actions={
            <div>
              <div className={disabled || job.hasChanges() ? classes.invisible : classes.actions}>
                <a href={url} rel="noopener noreferrer" target="_blank">
                  <IconButton aria-label="visit source" color="default" component="span">
                    <LinkIcon />
                  </IconButton>
                </a>
                {!url.startsWith("https://www.linkedin.com/") ? (
                  <IconButton aria-label="visit source" color="default" component="span" onClick={event => setPreviewing(!previewing)}>
                    <ViewColumnIcon />
                  </IconButton>
                ) : undefined}
                <ButtonWithMenu
                  color="primary"
                  onMenuItemClick={closeJob}
                  options={[
                    {id: 'not-a-fit-benefits', value: 'Benefits are lacking'},
                    {id: 'not-a-fit-unresponsive', value: 'Company unresponsive'},
                    {id: 'not-a-fit-compensation', value: 'Compensation too low'},
                    {id: 'not-a-fit-culture', value: 'Culture not a fit'},
                    {id: 'not-interested', value: 'Not interested'},
                    {id: 'not-a-fit-location', value: 'Location not a fit'},
                    {id: 'not-a-fit-role', value: 'Role not a fit'},
                    {id: 'another-candidate-selected', value: 'Another candidate selected'},
                  ]}
                  size="small"
                >
                  Action
                </ButtonWithMenu>
              </div>
              <div className={disabled || !job.hasChanges() ? classes.invisible : classes.actions}>
                <a href={url} rel="noopener noreferrer" target="_blank">
                  <IconButton aria-label="visit source" color="default" component="span">
                    <LinkIcon />
                  </IconButton>
                </a>
                <IconButton aria-label="visit source" color="default" component="span" onClick={event => setPreviewing(!previewing)}>
                  <ViewColumnIcon />
                </IconButton>
                <Button
                  aria-label="save changes"
                  color="secondary"
                  onClick={handleSave}
                  size="small"
                  variant="contained"
                >
                  Save
                </Button>
              </div>
              <div className={state === 'history' ? '' : classes.invisible}>
                <Button
                  color="secondary"
                  onClick={event => onChangeset('state')('research')}
                  size="small"
                  variant="contained">Start</Button>
              </div>
            </div>
          }
        >
          <div className={classes.root}>
            <Paper className={classes.document}>
              <div className={classes.progressWrapper}>
                <StateProgress activeStepId={getActiveStepId(state)} onScroll={onScroll} steps={getSteps()} />
              </div>

              <div className={classes.sections}>
                <Research
                  attributes={job.attributes}
                  disabled={disabled}
                  hasNoChange={job.isPristine.bind(job)}
                  onChangeset={onChangeset} />

                <Outreach
                  attributes={job.attributes}
                  disabled={disabled}
                  hasNoChange={job.isPristine.bind(job)}
                  messages={job.attributes.messages}
                  onChangeset={onChangeset} />

                <Interviewing
                  disabled={disabled}
                  interviews={job.attributes.interviews}
                  hasNoChange={job.isPristine.bind(job)}
                  onChangeset={onChangeset} />
              </div>
            </Paper>

            <Paper className={previewing ? classes.preview : classes.invisible}>
              <iframe className={classes.iframe} src={url} title={url} />
            </Paper>
          </div>
        </WithNavigation>
      </Fade>
    );
  } else {
    return <Loading />;
  }
}

const getSteps = _ => {
  return [
    {id: 1, label: 'Research'},
    {id: 2, label: 'Outreach'},
    {id: 3, label: 'Interviewing'},
  ]
}
