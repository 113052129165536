exports.concat = function() {
  return Array
    .from(arguments)
    .join('');
}

exports.dashcase = function(string) {
  return string
    .split(' ')
    .map(s => s.toLowerCase())
    .join('-');
}

exports.equal = function(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

exports.max = function(list) {
  return Math.max.apply(null, list);
}

exports.min = function(list) {
  return Math.min.apply(null, list);
}

exports.notEqual = function(a, b) {
  return !exports.equal(a, b);
}

exports.toUTC = function(date) {
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

exports.values = function(object) {
  return Object
    .keys(object)
    .map(k => object[k]);
}

exports.map = function(enumerable, callback) {
  return enumerate(enumerable).map(callback);
}

const enumerate = function(enumerable) {
  if (Array.isArray(enumerable)) {
    return enumerable;
  } else if (typeof enumerable === 'object' && enumerable !== null) {
    return Object.keys(enumerable).map(key => [enumerable[key], key]);
  } else {
    throw new Error('Not an enumerable type');
  }
}
