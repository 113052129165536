import React from 'react';
import { Grid } from '@material-ui/core';

import Section from './Base';
import {
  AutoResizeInput, EmployeeCount, FundingStages,
  IndustryTypes, UploadableImage,
} from '../../components/fields';
import { useStorage } from '../../hooks/firebase';

export default function Company(props) {
  const storage = useStorage();

  const {
    attributes,
    disabled,
    onChangeset,
  } = props;

  const onCapture = event => {
    storage.writeFile('images/companies', event.target.files[0], snapshot => {
      snapshot.ref.getDownloadURL()
        .then(url => {
          onChangeset('company')({
            ...attributes.company,
            image_url: url,
          });
        });
    });
  }

  return (
    <Section
      {...props}
      header="Research"
    >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UploadableImage
              disabled={disabled}
              onCapture={disabled ? (_ => {}) : onCapture}
              src={attributes.company.image_url} />
          </Grid>
          <Grid item xs={4}>
            <FundingStages
              defaultValue={attributes.funding_stage}
              disabled={disabled}
              onChange={(event, value) => onChangeset('funding_stage')(value)} />
          </Grid>
          <Grid item xs={4}>
            <IndustryTypes
              defaultValue={attributes.industry}
              disabled={disabled}
              onChange={(event, value) => onChangeset('industry')(value)} />
          </Grid>
          <Grid item xs={4}>
            <EmployeeCount
              defaultValue={attributes.employee_count}
              disabled={disabled}
              onChange={(event, value) => onChangeset('employee_count')(value)} />
          </Grid>

          <Grid item xs={12}>
            <AutoResizeInput
              defaultValue={attributes.competitors}
              disabled={disabled}
              label="Competitors"
              onChange={event => onChangeset('competitors')(event.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <AutoResizeInput
              defaultValue={attributes.business_model}
              disabled={disabled}
              label="Business Model"
              onChange={event => onChangeset('business_model')(event.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <AutoResizeInput
              defaultValue={attributes.customer}
              disabled={disabled}
              label="Customer"
              onChange={event => onChangeset('customer')(event.target.value)} />
          </Grid>
        </Grid>
    </Section>
  );
}
