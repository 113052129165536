import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  ExpansionPanel, ExpansionPanelActions,
  ExpansionPanelDetails, ExpansionPanelSummary,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTimeOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';
import GroupIcon from '@material-ui/icons/GroupOutlined';
import NotesIcon from '@material-ui/icons/NotesOutlined';
import TitleIcon from '@material-ui/icons/TitleOutlined';

import {
  AutoResizeInput,
  CommunicationTypes,
  DateTimePicker,
} from '.';
import {
  concat, dashcase,
} from '../../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
    width: '100%',
  },
  action: {
    display: 'flex',
    justifySelf: 'flex-end',
  },
  details: {
    flexDirection: 'column',
    paddingBottom: 0,
  },
  disabledColor: {
    color: '#9e9e9e',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  expansionPanelActions: {
    padding: '0 24px 16px 24px',
  },
  field: {
    alignSelf: 'center',
    fontSize: theme.typography.pxToRem(16),
  },
  h3: {
    fontSize: '1.2rem',
    marginBottom: 15,
    marginTop: 15,
  },
  helper: {
    alignSelf: 'center',
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 2),
  },
  icon: {
    marginRight: 20,
    marginTop: 5,
  },
  inputPaddingTop: {
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    width: '100%',
  },
  search: {
    width: 300,
  },
  summary: {
    paddingRight: '12px !important',
  },
  title: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default function InterviewPanel(props) {
  const classes = useStyles();

  const {
    disabled = false,
    onChange,
    onRemove,
  } = props;

  const [message, setMessage] = React.useState(props.message);
  const [expanded, setExpanded] = React.useState(false);

  const handleBlur = (field, value) => {
    setMessage({
      ...message,
      [field]: value,
    });

    onChange({
      ...message,
      [field]: value,
    });
  }

  const handleChange = (field, value) => {
    handleBlur(field, value);
  }

  const handleRemove = event => {
    onRemove(event);
  }

  return (
    <div className={classes.root} id={concat(dashcase(message.label), '-', message.index)}>
      <ExpansionPanel defaultExpanded={expanded} onChange={event => setExpanded(!expanded)}>
        <ExpansionPanelSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.title}>
            {message.label}
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.details}>
          <div className={classes.row}>
            <TitleIcon className={classes.icon} />
            <CommunicationTypes
              defaultValue={message.label}
              disabled={disabled}
              fullWidth={true}
              onChange={(event, value) => handleChange('label', value ? value : '')} />
          </div>

          <Typography className={classes.h3} variant="h3">
            Request
          </Typography>
 
          <div className={classes.row}>
            <AccessTimeIcon className={classes.icon} />
            <DateTimePicker
              defaultValue={message.outgoing_starts_at}
              disabled={disabled}
              disablePast={false}
              error={!message.outgoing_starts_at ? true : undefined}
              fullWidth={false}
              onChange={date => handleChange('outgoing_starts_at', date)}
              required={true} />
          </div>

          <div className={classes.row}>
            <GroupIcon className={classes.icon} />
          </div>

          <div className={classes.row}>
            <NotesIcon className={classes.icon} />
            <AutoResizeInput
              defaultValue={message.outgoing}
              disabled={disabled}
              error={!message.outgoing ? true : undefined}
              fullWidth={true}
              onChange={event => handleBlur('outgoing', event.target.value)} />
          </div>

          <Typography className={classes.h3} variant="h3">
            Response
          </Typography>

          <div className={classes.row}>
            <AccessTimeIcon className={classes.icon} />
            <DateTimePicker
              defaultValue={message.incoming_starts_at}
              disabled={disabled}
              disablePast={false}
              error={!message.incoming_starts_at ? true : undefined}
              fullWidth={false}
              onChange={date => handleChange('incoming_starts_at', date)}
              required={true} />
          </div>

          <div className={classes.row}>
            <GroupIcon className={classes.icon} />
          </div>

          <div className={classes.row}>
            <NotesIcon className={classes.icon} />
            <AutoResizeInput
              defaultValue={message.incoming}
              disabled={disabled}
              error={!message.incoming ? true : undefined}
              fullWidth={true}
              onChange={event => handleBlur('incoming', event.target.value)} />
          </div>
        </ExpansionPanelDetails>

        <ExpansionPanelActions className={classes.expansionPanelActions}>
          {Boolean(message) && !disabled ? (
            <Button color="default" onClick={handleRemove} size="small" variant="contained">
              Remove
            </Button>
          ) : <div />}
        </ExpansionPanelActions>
      </ExpansionPanel>
    </div>
  );
}
