import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Section from './Base';
import {
  CustomizedMenu, OutreachPanel,
} from '../../components/fields';
import { concat } from '../../common/aide';

export default function Outreach(props) {
  const {
    attributes,
    disabled,
    messages = [],
    onChangeset,
  } = props;

  const handleChangeset = (index, value) => {
    const change = messages.map(message => {
      return { ...message };
    })

    change[index] = value;

    onChangeset('messages')(change);
  }

  const handleAdd = event => {
    onChangeset('messages')(
      messages.map(message => {
        return { ...message };
      }).concat({
        index: messages.length,
        label: '',
      })
    );
  }

  const handleRemove = index => {
    onChangeset('messages')(messages.filter((message, i) => i !== index));
  }

  if (messages.length === 0 && attributes.cover_letter) {
    const messageChange = { label: '' };

    if (attributes.cover_letter) {
      messageChange.outgoing = attributes.cover_letter;
    }

    if (attributes.cover_letter_response) {
      messageChange.incoming = attributes.cover_letter_response;
    }

    onChangeset('messages')([messageChange]);
    onChangeset('cover_letter', undefined);
    onChangeset('cover_letter_response', undefined);
  }

  return (
    <Section
      {...props}
      header="Outreach"
      actions={
        disabled ?
          undefined : (
            <CustomizedMenu
              options={[{
                label: 'Add Communication', onClick: handleAdd,
              }]}>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </CustomizedMenu>
          )
      }
    >
      {messages.map((message, index) => (
        <OutreachPanel
          disabled={disabled}
          key={concat('outreach-panel-', index)}
          message={message}
          onChange={value => handleChangeset(index, value)}
          onRemove={event => handleRemove(index)} />
      ))}

    </Section>
  );
}
