import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  active: {
    alignSelf: 'center',
    backgroundColor: '#E4A444',
    flexGrow: 1,
    height: 5,
  },
  segment: {
    alignSelf: 'center',
    backgroundColor: '#333333',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    flexGrow: 1,
    height: 5,
    paddingRight: 4,
  },
  text: {
    color: '#E4A444',
    display: 'flex',
    fontSize: '1.1em',
    justifyContent: 'center',
    paddingTop: 8,
  },
}));

export default function StateProgress(props) {
  const classes = useStyles();

  const {
    activeStepId = 0,
    onScroll,
    steps = [],
  } = props;

  const isActive = step => {
    return step.id <= activeStepId ?
      classes.active :
      classes.segment;
  }
  
  return (
    <div className={classes.root}>
      {steps.map((step, index) => (
        <div className={isActive(step, index)} key={`step-${step.id}`}>
          <span className={classes.text} onClick={event => onScroll(event, step.label.toLowerCase())}>
            {step.label}
          </span>
        </div>
      ))}
    </div>
  );
}