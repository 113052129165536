import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  input: {
    // marginTop: 10,
    '&:hover': {
    },
  },
  inputError: {
    color: '#b82413',
  },
}));

export default function AutoResizingInput(props) {
  const classes = useStyles();

  const {
    id,
    autoFocus = false,
    className,
    defaultValue,
    disabled = false,
    fullWidth = true,
    label,
    multiline = true,
    onChange = _ => {},
    placeholder,
    required = false,
  } = props;

  const handleChange = event => onChange(event);

  const handleKeyUp = event => {
    const min = event.target.rows || 1;
    const max = event.target.rowsMax || 10;
    const count = (event.target.value.match(/\n/g) || "").length;
  
    if (count <= max && count >= min) {
      event.target.rows = count + 1;
    } else if (count < min) {
      event.target.rows = count + 1;
    }
  }

  const renderPlaceholder = _ => {
    if (placeholder) {
      return placeholder;
    } else if (required) {
      return 'is required';
    }
  }

  return <TextField
    id={id}
    autoFocus={autoFocus}
    className={className}
    classes={{
      focused: classes.inputFocus,
    }}
    disabled={disabled}
    error={(required && !defaultValue) ? true : undefined}
    fullWidth={fullWidth}
    helperText={required && !defaultValue && 'is required'}
    inputProps={{
      defaultValue: defaultValue,
    }}
    label={label}
    multiline={multiline}
    onBlur={handleChange}
    onKeyUp={handleKeyUp}
    placeholder={renderPlaceholder()}
    required={required}
  />;
}
