import React from 'react';
import { render } from 'react-dom';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import AuthenticatedApp from './AuthenticatedApp';

const renderApp = _ => {
  render(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AuthenticatedApp />
    </MuiPickersUtilsProvider>,
    document.getElementById('app')
  );
}

renderApp();

console.log('👋 This message is being logged by "index.js"');

// import * as serviceWorker from './serviceWorker';

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
