import React from 'react';
import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/storage';

import config from '../../config.json';

const app = firebase.initializeApp(config.firebase);

const Context = React.createContext();

const userReducer = function(state, model) {
  return {
    ...state,
    loading: false,
    model: model,
  };
};

const Provider = props => {
  const storage = useStorage();

  const updateProfileImage = (path, file) => {
    return new Promise((resolve, reject) => {
      storage.writeFile(path, file, snapshot => {
        snapshot.ref
          .getDownloadURL()
          .then(url => firebase.auth().currentUser.updateProfile({photoURL: url}))
          .then(_ => resolve())
          .catch(error => reject(error));
      });
    });
  }

  const updateProfileName = name => {
    return firebase.auth()
      .currentUser
      .updateProfile({displayName: name})
      .then(_ => dispatch(firebase.auth().currentUser));
  }

  const [user, dispatch] = React.useReducer(userReducer, {
    loading: true,
    model: null,
    signOut: _ => firebase.auth().signOut(),
    updateProfileImage: updateProfileImage,
    updateProfileName: updateProfileName,
  });

  React.useEffect(
    _ => {
      const subscription = firebase
        .auth()
        .onAuthStateChanged(model => dispatch(model));

      return _ => subscription();
    },
    []
  );

  return (
    <Context.Provider value={user}>
      {props.children}
    </Context.Provider>
  );
}

export const Auth = { Provider };

const useAuth = _ => React.useContext(Context);

const useStorage = function() {
  const ref = firebase.storage().ref();

  return {
    writeFile: (path, file, callback) => {
      const meta = {
        name: file.name,
        lastModified: file.lastModified,
        path: file.path,
        size: file.size,
        contentType: file.type,
      };

      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);
      return fileReader.onload = event => {
        return ref
          .child(path)
          .put(event.target.result, meta)
          .then(callback);
      }
    }
  }
}

const useNavigations = function(history) {
  return {
    toJobCreate: _ => history.push(`/jobs/create`),
    toJob:      id => history.push(`/jobs/${id}`),
    toTodoList:  _ => history.push('/'),
    back:        _ => history.goBack(),
  };
};

const useFirestore = function() {
  const user = useAuth().model;
  const firestore = firebase.firestore();

  return {
    addJob: (data, callback) => {
      return firestore
        .collection('candidate_jobs')
        .add(data)
        .then(callback);
    },
    queryCandidateHistoryList: callback => {
      return firestore
        .collection('candidate_histories')
        .where('owner_id', '==', user.uid)
        .orderBy('created_at', 'desc')
        .limit(20)
        .onSnapshot(callback, error => console.log('queryCandidateHistories error', error));
    },
    queryJobArchivedList: callback => {
      return firestore
        .collection('candidate_jobs')
        .where('owner_id', '==', user.uid)
        .where('state', 'in', [
          'archived', 'closed'
        ])
        .orderBy('created_at')
        .onSnapshot(callback, error => console.log('queryJobList error', error));
    },
    queryJobList: callback => {
      return firestore
        .collection('candidate_jobs')
        .where('owner_id', '==', user.uid)
        .where('state', 'in', [
          'history', 'research',
          'outreach', 'interviewing',
        ])
        .orderBy('created_at')
        .onSnapshot(callback, error => console.log('queryJobList error', error));
    },
    queryJobShow: (id, callback) => {
      return firestore
        .collection('candidate_jobs')
        .doc(id)
        .onSnapshot(callback);
    },
    queryJobUpdate: (ref, data, callback) => {
      return ref
        .update(data)
        .then(callback)
        .catch(error => {
          console.log('job update error', error);
        });
    },
    queryTodoList: callback => {
      return firestore
        .collection('todos')
        .where('owner_id', '==', user.uid)
        .onSnapshot(callback);
    }
  }
}


export {
  firebase, useAuth, useFirestore, useNavigations, useStorage
};

export const webClientId = config.webClientId;
export const Performance = firebase.performance();


export default app;
