import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { concat } from '../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#efefef',
    color: '#333333',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  h1: {
    fontWeight: 400,
  },
}));

export default function NotFound(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={concat(classes.h1, ' ', 'animated shake infinite duration-2s')} variant="h1">
        ¯\_(ツ)_/¯
      </Typography>
    </div>
  );
};
