import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'initial',
  },
  button: {
  },
  menuItem: {
    textAlign: 'left',
  }
}));

export default function ButtonWithMenu(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const {
    children,
    color = "default",
    onMenuClose,
    onMenuItemClick,
    options = [{id: 0, text: "No options"}],
    size = "large",
    variant = "contained",
  } = props;

  const onClick = event => {
    setAnchorEl(event.currentTarget);
  }

  const onClose = event => {
    if (onMenuClose) {
      onMenuClose(event);
    }

    setAnchorEl(null);
  }

  return (
    <div className={classes.root}>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={onClose}
      >
        {options.map((option, index) => (
          <MenuItem
            className={classes.menuItem}
            key={option.id}
            onClick={event => onMenuItemClick(event, options[index])}
          >
            {option.value}
          </MenuItem>
        ))}
      </Menu>

      <Button
        className={classes.button}
        color={color}
        onClick={onClick}
        size={size}
        variant={variant}
      >
        {children}
      </Button>
    </div>
  );
}