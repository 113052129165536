import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar, Divider, Paper, Typography,
} from '@material-ui/core';
import {
  Link,
} from 'react-router-dom';

import { concat } from '../../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    margin: '0 20px 20px 0',
    padding: 10,
    width: 270,
  },
  avatar: {
    marginRight: 10,
  },
  content: {
    display: 'flex',
    overflowX: 'hidden',
  },
  detail: {
    flexDirection: 'column',
  },
  divider: {
    margin: '10px 0',
  },
  heading: {
    color: '#333333',
    fontSize: '1.1em',
    fontWeight: 500,
    letterSpacing: '0.05em',
    lineHeight: 1.25,
    marginBottom: 0,
  },
  link: {
    textDecoration: 'none',
  },
  notices: {
  },
  subheading: {
    color: '#aaaaaa',
    fontSize: '0.75em',
    letterSpacing: '0.05em',
    whiteSpace: 'nowrap',
  },
}));

export default function Feature(props) {
  const classes = useStyles();

  const {
    id,
    attributes,
    notices = [],
  } = props;

  return (
    <div>
      <Paper className={classes.root}>
        <Link className={classes.link} to={concat('/jobs/', id)}>
          <div className={classes.content}>
            <Avatar className={classes.avatar} src={attributes.company.image_url} />

            <div className={classes.detail}>
              <Typography className={classes.heading} variant="subtitle1" gutterBottom>
                {attributes.company.name}
              </Typography>

              <Typography className={classes.subheading} variant="subtitle2" gutterBottom>
                {attributes.title}
              </Typography>
            </div>
          </div>
        </Link>

        {displayExisting(notices, classes)}
      </Paper>
    </div>
  );
}


const displayExisting = function(notices, classes) {
  if (notices.length) {
    return (
      <div>
        <Divider className={classes.divider} />

        <div className={classes.notices}>
          {notices}
        </div>
      </div>
    );
  }
}
