import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

import app from '../hooks/firebase';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  actions: {
    justifyContent: 'center',
    paddingLeft: 20,
  },
  heading: {
    fontSize: '2.5em',
    fontWeight: 500,
  },
  hero: {
    borderRight: '1px solid #E7E7E7',
    justifyContent: 'center',
    '& img': {
      width: 500,
    },
    paddingRight: 20,
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 800,
    padding: 30,
  },
  subheading: {
    fontSize: '1.2em',
    fontWeight: 300,
    lineHeight: 1.24,
    padding: '10px 20px 10px 0',
  },
}));

export default function Signin(props) {
  const classes = useStyles();
  const { history } = props;

  const handleSubmit = React.useCallback(
    async event => {
      event.preventDefault();
      const [email, password] = event.target.elements;

      try {
        await app
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value);
        history.push('/');
      } catch (error) {
        console.log('error', error);
      }
    },
    [history]
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.hero}>
          <Typography className={classes.heading} variant="h1">
            Help with your job search
          </Typography>

          <Typography className={classes.subheading} variant="subtitle2">
            From tracking searches and research to help with learning
            about new opportunities
          </Typography>

          <img alt="hero" src="https://firebasestorage.googleapis.com/v0/b/favor-75acf.appspot.com/o/any-do.jpg?alt=media&token=cdab2a0e-5f13-4248-b207-25f7bf675e07" />
        </div>
        <div className={classes.actions}>
          <form onSubmit={handleSubmit}>
            <label>
              Email
              <input name='email' type='email' placeholder='Email' />
            </label>

            <label>
              Email
              <input name='password' type='password' placeholder='Password' />
            </label>

            <button type='submit'>
              Signup
            </button>
          </form>
        </div>
      </Paper>
    </div>
  );
}
