import firebase from '../hooks/firebase';

import { notEqual, toUTC } from '../common/aide';

function FirebaseModel(snapshot, ref, collection) {
  if (snapshot) {
    this.id = snapshot.id;
  }

  const fieldMap = new FieldMap(snapshot ? snapshot.data() : {});

  const changes = function() {
    return Object.keys(fieldMap.fields)
      .filter(field => field !== 'meta')
      .filter(field => fieldMap.fields[field].isDirty())
      .map(field => fieldMap.fields[field]);
  }

  this.attributes = fieldMap.getAttributes();

  this.hasChanges = function() {
    return changes().length > 0;
  }

  this.isModified = function(attribute) {
    return fieldMap.hasChange(attribute);
  }

  this.isPristine = function(attribute) {
    return !fieldMap.hasChange(attribute);
  }

  this.setAttribute = (attribute, value) => {
    fieldMap.add(attribute, value);

    this.attributes = fieldMap.getAttributes();
  }

  const lastUpdates = _ => {
    return changes()
      .reduce((acc, field) => {
        acc[field.name] = toUTC(new Date());

        return acc;
      }, { ...((this.attributes.meta || {}).updates || {}) });
  }

  const add = _ => {
    this.setAttribute('created_at', new Date());

    return firebase.firestore()
      .collection(collection)
      .add({
        ...persistable(this.attributes),
        meta: {
          updates: lastUpdates(),
        },
        updates: lastUpdates(),
        created_at: toUTC(new Date()),
      })
      .then(docRef => ref = docRef)
      .then(_ => this.id = ref.id)
      .then(_ => console.debug('added'));
  }

  const update = _ => {
    this.setAttribute('updated_at', new Date());

    return ref
      .update({
        ...persistable(this.attributes),
        meta: {
          updates: {
            ...((this.attributes.meta || {}).updates || {}),
            ...lastUpdates(),
          }
        },
        updates: {
          ...this.attributes.updates,
          ...lastUpdates(),
        }
      })
      .then(_ => fieldMap.setToLatestChange())
      .then(_ => this.attributes = fieldMap.getAttributes())
      .then(_ => console.debug('updated'));
  }

  this.save = function() {
    if (ref) {
      return update();
    } else {
      return add();
    }
  }
}

const persistable = function(attributes) {
  return Object.keys(attributes).reduce((acc, attribute) => {
    const object = attributes[attribute];

    if (object instanceof Date) {
      acc[attribute] = toUTC(object);
    } else if (Array.isArray(object)) {
      acc[attribute] = object.map(i => persistable(i));
    } else if (object != null && typeof object === 'object') {
      acc[attribute] = persistable(object);
    } else {
      acc[attribute] = object;
    }

    return acc;
  }, {});
}

const FieldMap = function(attributes = {}) {
  const fields = {};
  this.fields = fields;

  Object.keys(attributes).reduce((acc, attribute) => {
    acc[attribute] = new Field(attribute, attributes[attribute]);

    return acc;
  }, fields);

  this.add = function(attribute, value) {
    fields[attribute] = fields[attribute] || new Field(attribute, null);

    return fields[attribute].add(value);
  }

  this.setToLatestChange = function() {
    Object.keys(fields).forEach(key => fields[key].setToLatestChange());
  }

  this.update = function(key, value) {
    fields[key].update(value);
  }

  this.getAttributes = function() {
    return Object.keys(fields).reduce((acc, key) => {
      acc[key] = fields[key].value;
      return acc;
    }, {});
  }

  this.hasChange = function(attribute) {
    if (attribute) {
      return !!fields[attribute] && fields[attribute].isDirty();
    } else {
      return !!Object.keys(fields).find(key => fields[key].isDirty());
    }
  }
}

const Field = function(name, value) {
  const list = [];
  let original = value;

  this.list = list;
  this.original = original;

  this.add = function(value) {
    if (notEqual(list[list.length - 1], value)) {
      list.push(value);
      this.value = list[list.length - 1];
    }
  }

  this.setToLatestChange = function() {
    if (list.length) {
      original = list[list.length - 1];
    }
  }

  this.update = function(value) {
    list.push(value);
    this.value = value;
    this.setToLatestChange();
  }

  this.name = name;
  this.value = original;

  this.isDirty = function() {
    return !!list.length && notEqual(original, list[list.length - 1]);
  }
}

export default FirebaseModel;
