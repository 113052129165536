import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { format, subDays } from 'date-fns';

import { TableView } from '../components/fields';
import { History, Job } from '../models';
import WithNavigation from './WithNavigation';
import { useFirestore } from '../hooks/firebase';
import { toUTC } from '../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
  },
  features: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  h2: {
    fontSize: 22,
    fontWeight: 200,
    lineHeight: 1.26,
  },
}));

export default function BrowsingHistory(props) {
  const classes = useStyles();
  const firestore = useFirestore();

  const [histories, setHistories] = useState([]);
  const rows = groupBy(daysOf(histories, 5), 'day');

  useEffect(
    _ => {
      const historyArray = [...histories];
      const unsubscribe = firestore
        .queryCandidateHistoryList(snapshot => {
          const array = snapshot.docChanges().reduce((acc, change) => {
            const index = acc.findIndex(j => j.id === change.doc.id);

            if (index === -1 || ((change.type === 'added' || change.type === 'modified') && index === -1)) {
              acc.push(new History(change.doc));
            } else if (change.type === 'modified' && index > -1) {
              acc[index] = new History(change.doc);
            } else if (change.type === 'removed' && index > -1) {
              acc = acc.filter(object => object.id !== change.doc.id);
            }

            return acc;
          }, historyArray);

          setHistories(array);
        });
    
      return _ => unsubscribe();
    },
    // eslint-disable-next-line
    [2],
  );

  const onCreate = id => event => {
    const history = histories.find(h => h.id === id);
    const {
      attributes,
    } = history;
    
    const job = new Job(null, 'candidate_jobs');

    job.setAttribute('closed_at', null);
    job.setAttribute('company', attributes.company);
    job.setAttribute('content', attributes.content);
    job.setAttribute('location', attributes.location);
    job.setAttribute('title', attributes.title)
    job.setAttribute('owner_id', attributes.owner_id);
    job.setAttribute('source', 'Favor');
    job.setAttribute('history_id', id);
    job.setAttribute('url', attributes.url);

    job.save()
      .then(_ => props.history.push(`/jobs/${job.id}`));
  }

  const tableViewKey = (group, index) => {
    return `history-table-view-${group.replace(' ', '-')}-${index}`;
  }

  const renderTableViews = _ => {
    return Object.keys(rows).map((group, index) => (
      <TableView
        columns={['time', 'company', 'title', 'location', 'base_salary']}
        header={group}
        key={tableViewKey(group, index)}
        onCreate={onCreate}
        rows={rows[group]} />
    ));
  }

  return (
    <WithNavigation
      {...props}
      title={
        <Typography className={classes.h2} variant="h2">
          Browsing History
        </Typography>
      }
    >
      <div className={classes.root}>
        <div className={classes.features}>
          {renderTableViews()}
        </div>
      </div>
    </WithNavigation>
  );
}

const daysAgo = number => {
  return subDays(toUTC(new Date()), number);
}

const daysOf = (histories, number) => {
  return histories
    .map(history => [history.id, history.attributes])
    .map(transform)
    .filter(history => history.created_at > daysAgo(number).getTime())
}

const groupBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const transform = ([id, history]) => {
  const date = new Date(history.created_at);

  return {
    id: id,
    base_salary: history.base_salary,
    day: format(date, 'EEEE, LLLL dd, yyyy'),
    company: history.company.name,
    time: format(date, 'p'),
    created_at: history.created_at,
    location: history.location.name,
    title: history.title,
    url: history.url,
  }
}
