import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Auth } from './hooks/firebase';
import {
  Archived, BrowsingHistory, JobShow, Main,
  PrivateRoute, Signin, Signup,
} from './screens';

import './authenticated-app.css';

export default function AuthenticatedApp(props) {
  return (
    <Auth.Provider>
      <Router>
        <PrivateRoute exact path="/browsing-history" component={BrowsingHistory} />
        <PrivateRoute exact path="/jobs/:id" component={JobShow} />
        <PrivateRoute exact path="/archived" component={Archived} />
        <PrivateRoute exact path="/" component={Main} />
        <PrivateRoute exact path="/build/index.html" component={Main} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/signup" component={Signup} />
      </Router>
    </Auth.Provider>
  );
};
