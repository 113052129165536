import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Badge, Chip } from '@material-ui/core';

import { InterviewNotes } from '../dialogs';
import { concat } from '../../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
  },
  avatar: {
    height: 24,
    width: 24,
  },
  avatarGroup: {
    position: 'absolute',
    left: 5,
    top: -2,
  },
  invisible: {
    display: 'none',
  },
}));

export default function InterviewNotice(props) {
  const classes = useStyles();

  const {
    contacts = [],
    feedback,
    label,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const onClick = event => setAnchorEl(!!anchorEl ? null : event.currentTarget);

  const onClose = event => setAnchorEl(null);

  const avatar = contact => {
    return <Avatar className={classes.avatar} src={contact.image_url} />;
  }
  

  return (
    <div>
      <Chip
        avatar={
          <Badge badgeContent={contacts.length} color="secondary">
            {contacts.length === 1 ? avatar(contacts[0]) : undefined}
            {contacts.length > 1 ? avatar({}) : undefined}
          </Badge>      
        }
        className={!feedback ? classes.chip : classes.invisible}
        label={concat(label, ' Feedback')}
        onClick={onClick}
        variant="outlined" />

      <InterviewNotes
        {...props}
        open={!!anchorEl}
        onClose={onClose} />
    </div>
  );  
}
