import React from 'react';

import StyledAutocomplete from './StyledAutocomplete';

const OPTIONS = [
  'E-Commerce',
  'Health Care',
  'Hospitality',
  'Internet',
  'News',
  'Publishing',
  'Recruiting',
  'Self-Storage',
];

export default function FundingStageAutocomplete(props) {
  const {
    defaultValue = '',
    disabled = false,
    fullWidth = true,
    onChange = _ => { throw new Error('on change is handler is required') },
    required = true,
  } = props;

  return <StyledAutocomplete
    defaultValue={defaultValue}
    disabled={disabled}
    fullWidth={fullWidth}
    label="Industry Types"
    onChange={onChange}
    options={OPTIONS}
    required={required} />;
};
