import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { firebase, webClientId } from '../hooks/firebase';
import { concat } from '../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  actions: {
    justifyContent: 'center',
    // paddingLeft: 20,
  },
  heading: {
    fontSize: '2.5em',
    fontWeight: 500,
  },
  hero: {
    borderRight: '1px solid #E7E7E7',
    justifyContent: 'center',
    '& img': {
      width: 500,
    },
    paddingRight: 20,
  },
  invisible: {
    display: 'none',
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 800,
    padding: 30,
  },
  subheading: {
    fontSize: '1.2em',
    fontWeight: 300,
    lineHeight: 1.24,
    padding: '10px 20px 10px 0',
  },
}));

export default function Signin(props) {
  const classes = useStyles();

  const uiConfig = createConfig({
    signInSuccessUrl: concat('/', props.match.params.redirect),
  });

  return (
    <div className={classes.root}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    </div>
  );
}

const createConfig = function(overrideOptions = {}) {
  return {
    signInFlow: 'redirect',
    signInSuccessUrl: '/',
    // callbacks: {
    //   signInSuccess: function(user, credential, redirect) {
    //     console.log('we can use these values');
    //   },
    // },
    signInOptions: [{
      authMethod: 'https://accounts.google.com',
      clientId: webClientId,
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      scopes: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/calendar.events',
      ],
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: 'emailLink',
    }],
    ...overrideOptions
  };
}
