import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Typography,
} from '@material-ui/core';

import { concat } from '../common/aide';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const byClosedAtDate = (a, b) => {
  if (a.closed_at > b.closed_at) {
    return 1;
  } else if (a.closed_at < b.closed_at) {
    return -1;
  } else {
    return 0;
  }
}

const toRow = ({ id, attributes: { closed_at, closed_reason, company = {}, state, title, location = {}, url } }) => {
  return {
    id: id,
    companyImageUrl: company.image_url,
    companyName: company.name,
    companyUrl: company.url,
    state: state,
    closed_at: closed_at,
    closed_reason: closed_reason,
    title,
    locationName: location.name,
    url,
  };
}

const useStyles = makeStyles({
  companyName: {
    fontSize: '0.85rem',
    fontWeight: 400,
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
  },
  image: {
    '& img': {
      border: '1px solid #cccccc',
      borderRadius: 4,
      height: 64,
      width: 64,
    },
    width: 66,
  },
  location: {
    fontSize: '1.15rem',
    fontWeight: 300,
    letterSpacing: '0.02em',
  },
  row: {
    color: '#555555',
    cursor: 'pointer',
  },
  state: {
    fontSize: '1rem',
    fontWeight: 300,
    letterSpacing: '0.02em',
    textTransform: 'capitalize',
  },
  table: {
    minWidth: 700,
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.26,
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export default function JobsTable(props) {
  const classes = useStyles();

  const {
    data,
  } = props;

  const navigateToJob = id => {
    props.history.push(concat('/jobs/', id));
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="jobs table">
        <TableBody>
          {data
            .map(toRow)
            .sort(byClosedAtDate)
            .map(row => (
              <StyledTableRow className={classes.row} key={row.id} onClick={event => navigateToJob(row.id)}>
                <StyledTableCell className={classes.image} component="th" scope="row">
                  <img alt="company logo" src={row.companyImageUrl} />
                </StyledTableCell>
                <StyledTableCell>
                  <Typography className={classes.title} variant="h2">
                    {row.title}
                  </Typography>
                  <Typography className={classes.companyName} variant="h4">
                    {row.companyName}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography className={classes.location} variant="h4">
                    {row.locationName}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography className={classes.state} variant="h4">
                    {row.closed_reason}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
