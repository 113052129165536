import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar, Button, Divider, Input, LinearProgress, Menu, 
} from '@material-ui/core';

import { UploadableImage } from '../../components/fields';
import { useAuth } from '../../hooks/firebase';
import { concat, toUTC } from '../../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 8,
    cursor: 'pointer',
    position: 'relative',
  },
  input: {
    '& .MuiInput-input': {
      padding: 0,
      textAlign: 'center',
    }
  },
  invisible: {
    visibility: 'hidden',
  },
  menu: {
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenu-paper': {
    },
  },
  userCard: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px 20px 12px 20px',
  },
  userCardActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 16px 16px 23px',
  },
  userCardEmail: {
    alignSelf: 'center',
    fontSize: '0.9em',
    fontWeight: 300,
  },
  userCardImage: {
    margin: '0 auto',
    '& img': {
      borderRadius: 50,
    },
  },
  userCardLogout: {
    borderRadius: 8,
    marginLeft: 20,
  },
  userCardName: {
    alignSelf: 'center',
    fontSize: '1.1em',
    fontWeight: 600,
    paddingTop: 12,
  },
  userDetail: {
    flexDirection: 'column',
    marginLeft: 10,
  },
  userEmail: {
    fontSize: '0.75em',
  },
  userImage: {
    border: '1px solid #555555',
    height: 36,
    width: 36,
  },
  menuItem: {
    textAlign: 'left',
  },
  userName: {
    fontSize: '0.95em',
    fontWeight: 600,
  },
  userProfile: {
    display: 'flex',
    alignItems: 'center',
  },
  userProgress: {
    height: '80px !important',
    width: '80px !important',
    '& .MuiCircularProgress-svg': {
      height: '80px !important',
      width: '80px !important',
    },
  },
}));

export default function UserProfile(props) {
  const classes = useStyles();
  const user = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [displayName, setDisplayName] = React.useState(user.model.displayName);
  const [editingName, setEditingName] = React.useState(false);

  const {
    fullWidth = true,
  } = props;

  const {
    email,
    photoURL,
  } = user.model;

  const onCapture = event => {
    setLoading(true);
    user
      .updateProfileImage(
        concat('images/users/', user.uid, '/', toUTC(new Date())),
        event.target.files[0],
      )
      .then(_ => setAnchorEl(null))
      .then(_ => setLoading(false));
  }

  const anchorPosition = _ => {
    if (anchorEl) {
      return {
        top: anchorEl.offsetParent.offsetTop - 213,
        left: anchorEl.offsetParent.offsetLeft + anchorEl.offsetLeft,
      };
    }
  }

  const onLogout = event => {
    user.signOut();
  }

  const onClick = event => {
    setAnchorEl(!!anchorEl ? null : event.currentTarget);
  }

  const onClose = event => {
    setAnchorEl(null);
  }

  const handleDisplayNameChange = event => {
    setDisplayName(event.target.value);
  }

  const handleDisplayNameKeyDown = event => {
    if (event.keyCode === 13) {
      writeDisplayName(event);
      event.currentTarget.blur(event);
    }
  }

  const writeDisplayName = event => {
    user.updateProfileName(displayName);
    setEditingName(false);
  }

  return (
    <div className={classes.root}>
      <Menu
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition()}
        className={classes.menu}
        keepMounted
        open={!!anchorEl}
        onClose={onClose}
      >
        <div className={classes.userCard}>
          <div className={classes.userCardImage}>
            <UploadableImage onCapture={onCapture} src={photoURL} />
          </div>
          <div className={classes.userCardName} onClick={_ => setEditingName(true)}>
            {editingName || !displayName ? (
              <Input
                autoFocus={true}
                className={classes.input}
                defaultValue={displayName || ''}
                disableUnderline={true}
                onBlur={writeDisplayName}
                onChange={handleDisplayNameChange}
                onKeyDown={handleDisplayNameKeyDown} />
            ) : displayName}
          </div>
          <div className={classes.userCardEmail}>{email}</div>
        </div>

        {loading ? <LinearProgress /> : <Divider />}

        <div className={classes.userCardActions}>
          <div className={classes.userProfile} onClick={onClick}>
            <Avatar alt={displayName} className={classes.userImage} src={photoURL} />

            <div className={classes.userDetail}>
              <div className={classes.userName}>{displayName}</div>
              <div className={classes.userEmail}>{email}</div>
            </div>

            <Button className={classes.userCardLogout} onClick={onLogout} variant="outlined">
              Sign Out
            </Button>
          </div>
        </div>
      </Menu>

      <div className={classes.userProfile} onClick={onClick}>
        <Avatar alt={displayName || 'Anonymous'} className={classes.userImage} src={photoURL} />

        {fullWidth ? (
          <div className={classes.userDetail}>
            <div className={classes.userName}>{displayName || 'Anonymous'}</div>
            <div className={classes.userEmail}>{email}</div>
          </div>
        ) : undefined }
      </div>
    </div>
  );
}
