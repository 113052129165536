import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    borderLeft: '4px solid transparent',
    marginBottom: 15,
    padding: '8px 12px 16px 12px',
  },
  content: {
    flexGrow: 1,
  },
  h3: {
    fontSize: 28,
    fontWeight: 400,
    lineHeight: 1.26,
  },
  header: {
    display: 'flex',
    padding: '8px 0 16px 0',
  },
}));

export default function Section(props) {
  const classes = useStyles();

  const {
    header,
    actions,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.content}>
        {
          typeof header === 'string' ? (
            <Typography id={header.toLowerCase().replace(' ', '-')} className={classes.h3} noWrap variant="h3">
              {header}
            </Typography>
          ) : header
        }
        </div>

        {actions}
      </div>

      <div className={classes.content}>
        {props.children}
      </div>
    </div>
  );
}