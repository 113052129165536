import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  IconButton, LinearProgress,
  TextField, Typography,
} from '@material-ui/core';
import {
  Link,
} from 'react-router-dom';
import AddIcon from '@material-ui/icons/AddOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import HistoryIcon from '@material-ui/icons/History';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActiveOutlined';
import SettingsIcon from '@material-ui/icons/Settings';

import { UserProfile } from '../components/fields';
import { useAuth } from '../hooks/firebase';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'stretch',
    backgroundColor: '#F4F3F0',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  actions: {
    flexWrap: 'nowrap',
  },
  fakeAppBar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 44,
    padding: '8px 20px',
  },
  body: {
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: 70,
    overflow: 'auto',
    position: 'relative',
  },
  companyName: {
    fontSize: '0.85rem',
    fontWeight: 400,
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
  },
  content: {
    margin: '10px 20px',
  },
  detail: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 20,
  },
  dialog: {
    margin: '0 auto',
    maxWidth: 640,
  },
  features: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
  },
  headerTitle: {
    flexGrow: 1,
  },
  icon: {
    color: '#000000',
    fontSize: '1.8rem',
    fontWeight: 300,
  },
  location: {
    fontSize: '1.05rem',
    fontWeight: 300,
    letterSpacing: '0.02em',
  },
  logoImage: {
    height: 60,
  },
  menu: {
    backgroundColor: '#AB7370',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 5px',
    position: 'fixed',
  },
  navigation: {
    flexShrink: 0,
  },
  preview: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 20,
  },
  progress: {
    marginTop: 20,
    width: '100%',
  },
  sections: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sectionsBottom: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: '12px 0',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.26,
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
  toolbar: {
    marginRight: 20,
  },
}));

export default function WithNavigation(props) {
  const classes = useStyles();
  const currentUser = useAuth();

  const [jobDialogOpen, setJobDialogOpen] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [jobJSON, setJobJSON] = React.useState(null);
  const [website, setWebsite] = React.useState('');

  const {
    actions,
    title,
    children,
  } = props;

  const handleAdd = event => {
    const url = [
      '/api/jobs/activate?id=',
      jobJSON.id,
    ].join('');

    currentUser.model.getIdToken().then(token => {
      const headers = new Headers();

      headers.set('Authorization', ['Bearer', token].join(' '));

      const request = new Request(url, {
        method: 'GET',
        headers: headers,
        mode: 'cors',
        cache: 'no-cache',
      });

      setFetching(true);

      return fetch(request)
        .then(res => res.json())
        .then(_ => {
          setFetching(false);
          setJobDialogOpen(false);
          setJobJSON(null);
          setWebsite(null);
        })
        .catch(error => console.log('error', error));
    });
  }

  const handleCancel = event => setJobDialogOpen(false);

  const handleQuery = event => {
    const url = [
      '/api/jobs/lookup?url=',
      encodeURIComponent(website)
    ].join('');

    currentUser.model
      .getIdToken()
      .then(token => {
        const headers = new Headers();

        headers.set('Authorization', ['Bearer', token].join(' '));

        const request = new Request(url, {
          method: 'GET',
          headers: headers,
          mode: 'cors',
          cache: 'no-cache',
        });

        return fetch(request)
          .then(res => res.json())
          .then(json => setJobJSON(json.data))
          .then(_ => setFetching(false))
          .catch(error => console.log('error', error.message));
      });
  }

  const handleTextChange = event => setWebsite(event.target.value);

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        <div
            onMouseOver={event => event.currentTarget.className = 'animated pulse 1s infinite'}
          onMouseOut={event => event.currentTarget.className = ''}
        >
          <img alt="logo" className={classes.logoImage} src="https://firebasestorage.googleapis.com/v0/b/favor-75acf.appspot.com/o/heart-briefcase.svg?alt=media&token=4603d461-ef89-45ef-829c-c3ece297c3f3" />
        </div>

        <div className={classes.sections}> 
          <IconButton size="medium" onClick={event => setJobDialogOpen(true)}>
            <AddIcon className={classes.icon} fontSize="large" />
          </IconButton>
          <Link to="/">
            <IconButton size="medium">
              <NotificationsActiveIcon className={classes.icon} fontSize="large" />
            </IconButton>
          </Link>
          <Link to="/archived">
            <IconButton>
              <ArchiveIcon className={classes.icon} fontSize="large" />
            </IconButton>
          </Link>
          <Link to="/browsing-history">
            <IconButton>
              <HistoryIcon className={classes.icon} fontSize="large" />
            </IconButton>
          </Link>
        </div>
        <div className={classes.sectionsBottom}>
          <div className={classes.grow}>
          </div>

          <Link className={classes.linkGrow} to="/settings">
            <IconButton>
              <SettingsIcon className={classes.icon} fontSize="large" />
            </IconButton>
          </Link>

          <UserProfile fullWidth={false} />
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.fakeAppBar}>
          <div className={classes.headerTitle}>
            {title}
          </div>
          <div className={classes.actions}>
            {actions}
          </div>
        </div>

        <div id="content" className={classes.content}>
          {children}
        </div>

        <Dialog
          aria-labelledby="form-dialog-title"
          className={classes.dialog}
          onClose={event => setJobDialogOpen(false)}
          open={jobDialogOpen}
        >
          <DialogTitle id="form-dialog-title">Add Job</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add a job, enter the url where it is located. We will retrieve and show the details before
              allowing you to add it to your list.
            </DialogContentText>
            <TextField
              id="job-url"
              autoFocus
              fullWidth
              label="Link to Job"
              margin="dense"
              onChange={handleTextChange}
              placeholder="example: https://jobs.lever.co/neuralink/23595"
              type="url"
            />

            {Boolean(jobJSON) ? (
              <div className={classes.preview}>
                <img
                  alt={jobJSON.company.name}
                  className={classes.logoImage}
                  src={jobJSON.company.public_url || jobJSON.company.image_url}
                />

                <div className={classes.detail}>
                  <Typography className={classes.title} variant="h2">
                    {jobJSON.title}
                  </Typography>
                  <Typography className={classes.companyName} variant="h4">
                    {jobJSON.company.name}
                  </Typography>
                  <Typography className={classes.location} variant="h4">
                    {jobJSON.location.name}
                  </Typography>
                </div>
              </div>
            ): undefined}

            {Boolean(fetching) ? (
              <div className={classes.progress}>
                <LinearProgress />
              </div>
            ): undefined}
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={handleCancel}>
              Cancel
            </Button>
    
            {Boolean(jobJSON) ? (
              <Button color="primary" onClick={handleAdd}>
                Add
              </Button>
            ) : (
              <Button color="primary" disabled={!Boolean(website)} onClick={event => {
                setFetching(true);
                handleQuery(event);
              }}>
                Query
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
