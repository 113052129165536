import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
  },
  avatar: {
    height: 24,
    width: 24,
  },
  icon: {
    color: '#e2325a !important',
  },
  invisible: {
    display: 'none',
  },
}));

export default function MissingInformationNotice(props) {
  const classes = useStyles();

  const {
    label,
    onClick,
  } = props;

  return (
    <div>
      <Chip
        className={classes.chip}
        label={label}
        onClick={onClick}
        variant="outlined" />
    </div>
  );
}
