import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Section from './Base';
import { CustomizedMenu, InterviewPanel } from '../../components/fields';
import { concat } from '../../common/aide';

export default function Interviewing(props) {
  const {
    disabled,
    interviews = [],
    onChangeset,
  } = props;

  const handleChangeset = (index, value) => {
    const change = interviews.map(interview => {
      return { ...interview };
    })

    change[index] = value;

    onChangeset('interviews')(change);
  }

  const handleAdd = event => {
    onChangeset('interviews')(
      interviews.map(interview => {
        return { ...interview };
      }).concat({
        index: interviews.length,
        label: '',
      })
    );
  }

  const handleRemove = index => {
    onChangeset('interviews')(interviews.filter((interview, i) => i !== index));
  }

  return (
    <Section
      {...props}
      header="Interviewing"
      actions={
        disabled ?
          undefined : (
            <CustomizedMenu
              options={[{
                label: 'Add Interview', onClick: handleAdd,
              }]}>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </CustomizedMenu>
          )
      }
    >
      {interviews.map((interview, index) => (
        <InterviewPanel
          key={concat('interview-panel-', index)}
          interview={interview}
          onChange={value => handleChangeset(index, value)}
          onRemove={event => handleRemove(index)} />
      ))}
    </Section>
  );
}
