import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';

import StyledAutocomplete from './StyledAutocomplete';
import { concat } from '../../common/aide';

const useStyles = makeStyles(theme => ({
  root: {
  },
  avatar: {
    marginRight: 20,
    '& img': {
      borderRadius: 16,
      height: 32,
      width: 32,
    },
  },
  contact: {
    display: 'flex',
    flexDirection: 'row',
  },
  detail: {
    alignSelf: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
  email: {
  },
  emailDisabled: {
    color: '#9e9e9e',
  },
  icon: {
    alignSelf: 'center',
    display: 'flex',
  },
  invisible: {
    display: 'none',
  },
  name: {
    fontSize: theme.typography.pxToRem(15),
  },
  remove: {
    alignSelf: 'center',
  },
  selected: {
    marginTop: 20,
  },
}));

export default function Contacts(props) {
  const classes = useStyles();

  const {
    disabled = false,
    freeSolo = true,
    fullWidth = true,
    onChange = _ => { throw new Error('on change is handler is required') },
    options = [{
      image_url: 'https://lh3.googleusercontent.com/a-/AAuE7mAykzsRnKKmteoV5I0sVIfLIJJq9uUn08gYoSmfug=s140-p-k-rw-no',
      name: {
        first: 'Eric',
        last: 'Ho',
      },
      email: 'eric@ripplematch.com',
    }, {
      image_url: 'https://firebasestorage.googleapis.com/v0/b/favor-75acf.appspot.com/o/images%2Fcontacts%2FA35GYHtZJYO4LVasJXgicGdLSno1%2Ftucker-fross.jpeg?alt=media&token=70c4e86b-269c-4462-98e3-b0f76bd0b325',
      name: {
        first: 'Tucker',
        last: 'Fross',
      },
      email: 'tucker@ripplematch.com',
    }, {
      image_url: 'https://firebasestorage.googleapis.com/v0/b/favor-75acf.appspot.com/o/images%2Fcontacts%2FA35GYHtZJYO4LVasJXgicGdLSno1%2Fyohanan-radchenko.jpeg?alt=media&token=bcea7afc-f289-4bd4-a3dc-157bd65cdd6c',
      name: {
        first: 'Yohanan',
        last: 'Radchenko',
      },
      email: 'yohanan@ripplematch.com',
    }],
    placeholder = 'Add contacts',
    required = false,
  } = props;

  const [contacts, setContacts] = React.useState((props.contacts || []).reduce((acc, contact) => {
    acc[contact.email] = contact;
    return acc;
  }, {}));

  const objectToArray = object => {
    return Object.keys(object).map(key => object[key]);
  }

  const handleChange = (event, value) => {
    let change = {
      ...contacts,
    };

    if (!!value && typeof value === 'object') {
      change[value.email] = value;

      setContacts(change);
      onChange(event, Object.keys(change).map(email => change[email])); 
    } else if (typeof value === 'string' && value.length) {
      change[value] = {
        email: value,
        image_url: '',
        name: {
          first: '',
          last: '',
        },
      };

      setContacts(change);
      onChange(event, objectToArray(change));
    } else {
      console.debug(`value of autocomplete field => ${value}`);
    }
  }

  const handleRemove = (event, email) => {
    console.debug('removing contact', email);

    const change = {
      ...contacts,
    };

    delete change[email];

    setContacts(change);
    onChange(event, objectToArray(change));
  }

  const renderContact = options => {
    const fullName = `${options.name.first} ${options.name.last}`;

    return (
      <div className={classes.contact}>
        <Avatar alt={fullName} className={classes.avatar} src={options.image_url} />
        <div className={classes.detail}>
          <Typography className={classes.name} variant="h6">
            {fullName}
          </Typography>
          <Typography className={disabled ? classes.emailDisabled : classes.email} variant="body2">
            {options.email}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <StyledAutocomplete
          defaultValue={''}
          disableClearable={true}
          disabled={disabled}
          disableOpenOnFocus={true}
          freeSolo={freeSolo}
          fullWidth={fullWidth}
          getOptionLabel={option => ""}
          helperText=""
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          renderOption={renderContact}
          required={required} />
      </div>

      <div className={Object.keys(contacts).length ? classes.selected : classes.invisible}>
        {Object.keys(contacts).map(key => {
          const {
            email,
            image_url,
            name: {
              first,
              last,
            },
          } = contacts[key];

          return (
            <div className={classes.contact} key={concat('contacts-', email)}>
              <Avatar alt={concat(first, ' ', last)} className={classes.avatar} src={image_url} />
              <div className={classes.detail}>
                <Typography className={disabled ? classes.emailDisabled : classes.email} variant="body2">
                  {email}
                </Typography>
              </div>
              <IconButton className={disabled ? classes.invisible : classes.remove} onClick={event => handleRemove(event, email)} size="small">
                <CloseIcon className={classes.icon} fontSize="small" />
              </IconButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};
