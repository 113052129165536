import React from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';


const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    '& > *': {
      marginLeft: 8,
    }
  },
  highlight:
    theme.palette.type === 'light'? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    } : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const {
    header,
    onCreate,
    row,
  } = props;

  return (
    <Toolbar className={clsx(classes.root, {[classes.highlight]: !!row.id})}>
      {!!row.id ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          "{row.title}" selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {header}
        </Typography>
      )}

      {!!row.id ? (
        <div className={classes.actions}>
          <Tooltip title="View Job">
            <Button href={row.url} rel="noopener" target="_blank" variant="contained">
              Source
            </Button>
          </Tooltip>
          <Tooltip title="Pursue this Job">
            <Button color="primary" onClick={onCreate(row.id)} variant="contained">
              Pursue
            </Button>
          </Tooltip>
        </div>
      ) : null}
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();

  const {
    columns = [],
    header,
    onCreate,
    rows = [],
  } = props;

  const [rowSelected, setRowSelected] = React.useState(-1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          header={header}
          onCreate={onCreate}
          row={rowSelected}
        />

        <div className={classes.tableWrapper}>
          <Table className={classes.table} size="medium">
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <TableRow
                    hover
                    key={`table-row-${row.id}`}
                    onClick={event => setRowSelected(row.id === rowSelected.id ? -1 : row)}
                    selected={row.id === rowSelected.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={row.id === rowSelected.id} />
                    </TableCell>
                    <TableCell component="th" id={`table-checkbox-${row.id}`} scope="row" padding="none">
                      {row[columns[0]]}
                    </TableCell>

                    {columns.slice(1).map((column, index) => (
                      <TableCell key={`history-table-cell-${index}`}>{row[column]}</TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}