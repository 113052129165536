import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// import { differenceInHours } from 'date-fns'

import JobsTable from '../components/JobsTable';
// import {
//   InterviewNotice, InterviewReminder, MissingInformationNotice,
// } from '../components/fields';
import { Job } from '../models';
import WithNavigation from './WithNavigation';
// import {
//   concat,
//   min,
// } from '../common/aide';
import toAnalyticsData from '../common/analyticsData';
import { useFirestore } from '../hooks/firebase';

const useStyles = makeStyles(theme => ({
  root: {
  },
  features: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  h2: {
    fontSize: 22,
    fontWeight: 200,
    lineHeight: 1.26,
  },
}));

function jobsReducer(state, changes) {
  const jobs = state.reduce((acc, job) => {
    acc[job.id] = job;

    return acc;
  }, {});

  changes.forEach(change => {
    switch (change.type) {
      case 'removed':
        delete jobs[change.doc.id];
        break;

      default:
        jobs[change.doc.id] = new Job(change.doc);
    }
  });

  return Object.keys(jobs).map(key => jobs[key]);
}

export default function Main(props) {
  const classes = useStyles();
  const firestore = useFirestore();

  const [jobs, dispatch] = React.useReducer(jobsReducer, []);

  React.useEffect(
    _ => {
      const unsubscribe = firestore
        .queryJobList(snapshot => dispatch(snapshot.docChanges()));
    
      return _ => unsubscribe();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <WithNavigation
      {...props}
      title={
        <Typography className={classes.h2} variant="h2">
          Active Jobs
        </Typography>
      }
    >
      <div className={classes.root}>
        <JobsTable {...props} data={
          limitToProcess(jobs)
            .map(toFeatureData)
            .sort(byTimeToAct)
        } />
      </div>
    </WithNavigation>
  );
}

const limitToProcess = function(jobs) {
  return jobs
    .filter(job => Job.INPROCESS_STATES.includes(job.attributes.state));
}

const toFeatureData = function(job) {
  return {
    id: job.id,
    attributes: job.attributes,
    job: job,
    analytics: toAnalyticsData(job.attributes),
  };
}

const byTimeToAct = function(a, b) {
  if (a.analytics.overallTimeToAct < b.analytics.overallTimeToAct) {
    return -1;
  } else if (a.timeToAct > b.timeToAct) {
    return 1;
  } else {
    return 0;
  }
}

// const SECTIONS = {
//   research: {
//     fields: [
//       'business_model', 'competitors', 'customer',
//       'employee_count', 'funding_stage', 'industry'
//     ],
//     hourLimit: 48,
//   }
// };

// const researchValues = function(object) {
//   return values(object, key => SECTIONS.research.fields.includes(key));
// }

// const values = function(object, expression = i => i) {
//   return Object
//     .keys(object)
//     .filter(expression)
//     .map(key => object[key]);
// }

// const pastResearchTime = function(attributes, days) {
//   const {
//     meta: {
//       updates,
//     } = {},
//     updated_at,
//   } = attributes;

//   return differenceInHours(updated_at, min(researchValues(updates || {}))) > days;
// }

// const anyRequiredFields = function(list, attributes = {}) {
//   return list.find(field => attributes[field]);
// }

// const notAnyRequiredFields = function(list, attributes = {}) {
//   return !anyRequiredFields(list, attributes);
// }

// const notices = function(history, {job, id, analytics, attributes}) {
//   const {
//     cover_letter,
//     cover_letter_response,
//     interviews,
//     state,
//   } = attributes;

//   switch(state) {
//     case 'research':
//       if (notAnyRequiredFields(SECTIONS.research.fields, attributes)) {
//         return [
//           <MissingInformationNotice
//             key={concat('research-notice-', id)}
//             label="Ready to start?"
//             onClick={event => history.push(concat('/jobs/', id, '#research'))} />
//         ];
//       } else if (pastResearchTime(attributes, 48)) {
//         return [
//           <MissingInformationNotice
//             key={concat('research-notice-', id)}
//             label="Finish your research!"
//             onClick={event => history.push(concat('/jobs/', id, '#research'))} />
//         ];
//       } else {
//         return [];
//       }
//     case 'outreach':
//       if (!cover_letter) {
//         return [
//           <MissingInformationNotice
//             key={concat('outreach-notice-', id)}
//             label="Make first contact!"
//             onClick={event => history.push(concat('/jobs/', id, '#outreach'))} />
//         ];
//       } else if (!cover_letter_response && analytics.outreach.timeToAct < 0) {
//         return [
//           <MissingInformationNotice
//             key={concat('outreach-notice-', id)}
//             label="Heard a response yet?"
//             onClick={event => history.push(concat('/jobs/', id, '#outreach'))} />
//         ];
//       } else {
//         return [];
//       }
//     case 'interviewing':
//       return interviews
//         .filter(i => !i.feedback)
//         .map((i, index) => {
//           if (i.starts_at > new Date()) {
//             return <InterviewReminder
//               key={concat('interview-notice-', i.index)}
//               history={history}
//               job={job}
//               onClick={event => history.push(concat('/jobs/', job.id, '#', i.label.toLowerCase().replace(' ', '-'), '-', index))}
//               {...i} />;
//           } else {
//             return <InterviewNotice job={job} history={history} key={concat('interview-notice-', i.index)} {...i} />;
//           }
//         });
//     default:
//       return new Error(`state unknown ${state}`);
//   }
// }
