import React from 'react';

import StyledAutocomplete from './StyledAutocomplete';

const OPTIONS = [
  'Bootstrapped',
  'Seed/Angel',
  'Series A',
  'Series B',
  'Series C',
  'Series D',
  'Series E',
  'Series F',
  'Private Equity',
  'Corporate Round',
  'Public',
  'Unknown',
];

export default function FundingStages(props) {
  const {
    defaultValue = '',
    disabled = false,
    fullWidth = true,
    onChange = _ => { throw new Error('on change is handler is required') },
    required = true,
  } = props;

  return <StyledAutocomplete
    defaultValue={defaultValue}
    disabled={disabled}
    freeSolo={false}
    fullWidth={fullWidth}
    label="Funding Stages"
    onChange={onChange}
    options={OPTIONS}
    required={required} />;
};
