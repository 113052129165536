import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import StyledAutocomplete from './StyledAutocomplete';

const OPTIONS = [
  'Email',
  'LinkedIn',
];

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

export default function CommunicationTypes(props) {
  const classes = useStyles();

  const {
    defaultValue = '',
    disabled = false,
    freeSolo = true,
    fullWidth = true,
    onChange = _ => { throw new Error('on change is handler is required') },
    options = OPTIONS,
    placeholder = 'Add communication type',
    required = true,
  } = props;

  const handleChange = (event, value) => {
    onChange(event, value);
  }

  return (
    <div className={classes.root}>
      <StyledAutocomplete
        defaultValue={defaultValue}
        disabled={disabled}
        freeSolo={freeSolo}
        fullWidth={fullWidth}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        required={required} />
    </div>
  );
};

